import { combineReducers } from 'redux';
import users, * as fromUsers from './users';

import webpages, * as fromWebpages from './webpages';
import websites, * as fromWebsites from './websites';
/* GENERATOR */

export default combineReducers({
  /* GENERATOR_COMBINED */
  websites,
  webpages,
  users,
});

export const getUser = (state, id) => fromUsers.getUser(state.users, id, state);

/* GENERATOR_EXPORTS */
export const getWebsite = (state, id) =>
  fromWebsites.getEntity(state.websites, id, state);

export const getVisibleWebsites = (state, filter) =>
  fromWebsites.getVisible(state.websites, filter, state);

export const getWebsitesStatus = (state, filter) =>
  fromWebsites.getStatus(state.websites, filter);
export const getWebpage = (state, id) =>
  fromWebpages.getEntity(state.webpages, id, state);

export const getVisibleWebpages = (state, filter) =>
  fromWebpages.getVisible(state.webpages, filter, state);

export const getWebpagesStatus = (state, filter) =>
  fromWebpages.getStatus(state.webpages, filter);
