import {
  CREATE_WEBPAGE_START,
  CREATE_WEBPAGE_SUCCESS,
  CREATE_WEBPAGE_ERROR,
  UPDATE_WEBPAGE_START,
  UPDATE_WEBPAGE_ERROR,
  UPDATE_WEBPAGE_SUCCESS,
  DELETE_WEBPAGE_START,
  DELETE_WEBPAGE_ERROR,
  DELETE_WEBPAGE_SUCCESS,
} from '../../constants';

const webpages = (state = {}, action) => {
  switch (action.type) {
    case CREATE_WEBPAGE_START:
    case UPDATE_WEBPAGE_START:
    case DELETE_WEBPAGE_START: {
      return {
        pending: true,
        success: false,
        error: null,
      };
    }

    case CREATE_WEBPAGE_ERROR:
    case UPDATE_WEBPAGE_ERROR:
    case DELETE_WEBPAGE_ERROR: {
      return {
        pending: false,
        success: false,
        error: action.message,
      };
    }
    case CREATE_WEBPAGE_SUCCESS:
    case UPDATE_WEBPAGE_SUCCESS:
    case DELETE_WEBPAGE_SUCCESS: {
      return {
        pending: false,
        success: true,
        error: null,
      };
    }

    default:
      return state;
  }
};
export default webpages;

export const getStatus = (state, id) => state; // [id] || {};
