import { schema } from 'normalizr';

export const user = new schema.Entity('users');

user.define({});

export const log = new schema.Entity('logs', {
  actor: user,
});

export const userList = [user];

export const webpage = new schema.Entity('webpages', {});
export const webpageList = [webpage];
export const website = new schema.Entity(
  'websites',
  {},
  { idAttribute: (value) => value.projectName },
);
export const websiteList = [website];
/* GENERATOR */
