import { combineReducers } from 'redux';
import { denormalize } from 'normalizr';
import createList, * as fromList from './createWebpageList';
import byId, * as fromById from './webpageById';
import {
  webpageList as webpageListSchema,
  webpage as webpageSchema,
} from '../store/schema';

const listByFilter = combineReducers({
  all: createList('all'),
  infos: createList('infos'),
  blog: createList('blog'),
});
export default combineReducers({
  byId,
  listByFilter,
});

const hydrateList = (state, data, entities) =>
  denormalize(
    { webpages: data },
    { webpages: webpageListSchema },
    {
      ...entities,
      webpages: state.byId,
    },
  );

export const getStatus = (state, filter) =>
  fromList.getStatus(state.listByFilter[filter]);
const hydrateEntity = (data, entities) =>
  denormalize(data, webpageSchema, entities);

export const getEntity = (state, id, entities) => {
  const webpage = fromById.getEntity(state.byId, id);
  return hydrateEntity(webpage, entities);
};

export const getVisible = (state, filter, entities) => {
  const ids = fromList.getIds(state.listByFilter[filter]);
  return hydrateList(state, ids, entities).webpages || [];
};
