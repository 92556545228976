import {
  CREATE_WEBSITE_START,
  CREATE_WEBSITE_SUCCESS,
  CREATE_WEBSITE_ERROR,
  UPDATE_WEBSITE_START,
  UPDATE_WEBSITE_ERROR,
  UPDATE_WEBSITE_SUCCESS,
  DELETE_WEBSITE_START,
  DELETE_WEBSITE_ERROR,
  DELETE_WEBSITE_SUCCESS,
} from '../../constants';

const websites = (state = {}, action) => {
  switch (action.type) {
    case CREATE_WEBSITE_START:
    case UPDATE_WEBSITE_START:
    case DELETE_WEBSITE_START: {
      return {
        pending: true,
        success: false,
        error: null,
      };
    }

    case CREATE_WEBSITE_ERROR:
    case UPDATE_WEBSITE_ERROR:
    case DELETE_WEBSITE_ERROR: {
      return {
        pending: false,
        success: false,
        error: action.message,
      };
    }
    case CREATE_WEBSITE_SUCCESS:
    case UPDATE_WEBSITE_SUCCESS:
    case DELETE_WEBSITE_SUCCESS: {
      return {
        pending: false,
        success: true,
        error: null,
      };
    }

    default:
      return state;
  }
};
export default websites;

export const getStatus = (state, id) => state || {};
