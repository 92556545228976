import {
  LOAD_WEBSITES_SUCCESS,
  CREATE_WEBSITE_SUCCESS,
  UPDATE_WEBSITE_SUCCESS,
} from '../constants';


export default function byId(state = {}, action) {
  switch (action.type) {

    case LOAD_WEBSITES_SUCCESS:
    case UPDATE_WEBSITE_SUCCESS:
    case CREATE_WEBSITE_SUCCESS: {
      return {
        ...state,
        ...action.payload.entities.websites
      }
    }

    default:
      return state;
  }
}

export const getEntity = (state, id) => state[id];

