import { combineReducers } from 'redux';

import users, * as fromUser from './ui/users';
import { SESSION_LOGOUT_SUCCESS } from '../constants';
import loading from './ui/loading';
import * as fromUpload from './ui/upload';

import webpages, * as fromWebpage from './ui/webpages';
import websites, * as fromWebsite from './ui/websites';
/* GENERATOR_IMPORTS */

const uiReducer = combineReducers({
  /* GENERATOR_COMBINE */
  websites,
  webpages,

  users,
  loading,
});
export default (state, action) => {
  if (action.type === SESSION_LOGOUT_SUCCESS) {
    // eslint-disable-next-line no-param-reassign
    state = undefined;
  }
  return uiReducer(state, action);
};

/* GENERATOR_EXPORTS */
export const getWebsiteUpdates = state =>
  fromWebsite.getStatus(state.websites);
export const getWebpageUpdates = (state, id) =>
  fromWebpage.getStatus(state.webpages, id);
export const getUploadStatus = (state) => fromUpload.getStatus(state.upload);

export const getAccountUpdates = (state, id) =>
  fromUser.getStatus(state.users, id) || {};
