import { combineReducers } from 'redux';
import user from './user';
import runtime from './runtime';
import intl, * as fromIntl from './intl';
import entities, * as fromEntity from './entities';
import ui, * as fromUi from './ui';
import consent from './consent';
import system from './system';
import pageInfo, * as fromPageInfo from './pageInfo';
import layoutSize, * as fromResponsive from './responsive';

export default combineReducers({
  user,
  runtime,
  intl,
  entities,
  ui,
  consent,
  system,
  pageInfo,
  layoutSize,
});

/* GENERATOR */
export const getVisibleWebsites = (state, filter) =>
  fromEntity.getVisibleWebsites(state.entities, filter);

export const getWebsite = (state, id) =>
  fromEntity.getWebsite(state.entities, id);

export const getWebsitesStatus = (state, filter) =>
  fromEntity.getWebsitesStatus(state.entities, filter);

export const getWebsiteUpdates = (state, filter) =>
  fromUi.getWebsiteUpdates(state.ui, filter);
export const getVisibleWebpages = (state, filter) =>
  fromEntity.getVisibleWebpages(state.entities, filter);

export const getWebpage = (state, id) =>
  fromEntity.getWebpage(state.entities, id);

export const getWebpagesStatus = (state, filter) =>
  fromEntity.getWebpagesStatus(state.entities, filter);

export const getWebpageUpdates = (state, id) =>
  fromUi.getWebpageUpdates(state.ui, id);

export const getUploadStatus = (state) => fromUi.getUploadStatus(state.ui);

export const getLayoutSize = (state) =>
  fromResponsive.getLayoutSize(state.layoutSize);
export const getResourcePageInfo = (state, resource, filter) =>
  fromPageInfo.getPageInfo(state.pageInfo, resource, filter);

export const getSessionUser = (state) =>
  fromEntity.getUser(state.entities, state.user);

export const getAccountUpdates = (state, id) =>
  fromUi.getAccountUpdates(state.ui, id);

export const getUser = (state, id) => fromEntity.getUser(state.entities, id);

export const getConsent = (state) => state.consent;
export const getLocale = (state) => fromIntl.getLocale(state);
export const getRecaptchaKey = (state) => state.system.recaptchaKey;
export const getWebPushKey = (state) => state.system.webPushKey;
export const getDroneBranch = (state) => state.system.droneBranch;
export const getDroneBuild = (state) => state.system.droneBuild;
