import merge from 'lodash.merge';
import {
  LOAD_USERS_SUCCESS,
  CREATE_USER_SUCCESS,
  UPDATE_USER_SUCCESS,
  UPLOAD_AVATAR_SUCCESS,
  RESET_PASSWORD_SUCCESS,
  FIND_USER_SUCCESS,
  FETCH_USER_SUCCESS,
  SESSION_LOGIN_SUCCESS,
  SSE_UPDATE_SUCCESS,
} from '../constants';

const handleUsers = (state, action) => {
  const { users } = action.payload.entities;
  const newState = Object.keys(users).reduce(
    (acc, curr) => ({ ...acc, [curr]: { ...state[curr], ...users[curr] } }),
    {},
  );
  return {
    ...state,
    ...newState,
  };
};

export default function byId(state = {}, action) {
  switch (action.type) {
    case LOAD_USERS_SUCCESS:
    case CREATE_USER_SUCCESS:
    case UPLOAD_AVATAR_SUCCESS:
    case RESET_PASSWORD_SUCCESS:
    case FIND_USER_SUCCESS:
    case SESSION_LOGIN_SUCCESS: {
      return merge({}, state, action.payload.entities.users);
    }

    case SSE_UPDATE_SUCCESS: {
      return action.payload.entities.users
        ? merge({}, state, action.payload.entities.users)
        : state;
    }
    case UPDATE_USER_SUCCESS: {
      // bc of deleted followees
      return handleUsers(state, action);
    }

    case FETCH_USER_SUCCESS: {
      // bc of permissions
      return handleUsers(state, action);
    }

    default:
      return state;
  }
}

export const getUser = (state, id) => state[id];
