import { combineReducers } from 'redux';
import { denormalize } from 'normalizr';
import createList, * as fromList from './createWebsiteList';
import byId, * as fromById from './websiteById';
import { websiteList as websiteListSchema, website as websiteSchema } from './../store/schema';

const listByFilter = combineReducers({
  all: createList('all'),
});
export default combineReducers({
  byId,
  listByFilter,
});

const hydrateList = (state, data, entities) =>
  denormalize(
    { websites: data },
    { websites: websiteListSchema },
    {
      ...entities,
      websites: state.byId,
    },
  );

export const getStatus = (state,filter) => fromList.getStatus(state.listByFilter[filter])
const hydrateEntity = (data, entities) => denormalize(data, websiteSchema, entities);

export const getEntity = (state, id, entities) => {
  const website = fromById.getEntity(state.byId, id);
  return hydrateEntity(website, entities)
};

export const getVisible = (state, filter, entities) => {
  const ids = fromList.getIds(state.listByFilter[filter]);
  return hydrateList(state, ids, entities).websites ||[];
}
