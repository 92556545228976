import {
  LOAD_WEBPAGES_SUCCESS,
  CREATE_WEBPAGE_SUCCESS,
  UPDATE_WEBPAGE_SUCCESS,
} from '../constants';


export default function byId(state = {}, action) {
  switch (action.type) {

    case LOAD_WEBPAGES_SUCCESS:
    case UPDATE_WEBPAGE_SUCCESS:
    case CREATE_WEBPAGE_SUCCESS: {
      return {
        ...state,
        ...action.payload.entities.webpages
      }
    }

    default:
      return state;
  }
}

export const getEntity = (state, id) => state[id];

